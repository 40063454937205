import { Button, Typography } from "@mui/material";
import React from "react";
import oncoLogoWhite from "../../Assets/oncoLogoWhite.png";
import { muiStyles } from "../../Styles/muiStyles";
import "../../Styles/styles.css";
import { Link } from "react-router-dom";

const Footer = ({ language }) => {
  return (
    <div id="contact-us" className="footer bg--secondary1">
      <div id="footer-left-section">
        <img id="footer-logo" src={oncoLogoWhite} alt="oncoPHR logo" />
      </div>
      <div id="footer-details">
        <div className="footer-container1">
          {/* <div className="wrap-containers">
          
        </div> */}
          <div className="container--col1">
            <a href="#about-us">
              {language === "Hindi" ? "हमारे बारे में" : "ABOUT US"}
            </a>
          </div>
          <div className="container--col1">
            <a href="#getting-started">
              {language === "Hindi"
                ? "यह काम किस प्रकार करता है"
                : "HOW IT WORKS"}
            </a>
            <a href="#getting-started" style={{ color: "#E6E6E6" }}>
              {language === "Hindi"
                ? "रिकार्ड कैसे अपलोड करें"
                : "How to Upload Records"}
            </a>
            <a href="#getting-started" style={{ color: "#E6E6E6" }}>
              {language === "Hindi"
                ? "रिकॉर्ड्स तक कैसे पहुंचें"
                : "How to Access Records"}
            </a>
          </div>
          <div className="container--col1">
            <a href="#faq">
              {language === "Hindi" ? "सामान्य प्रश्न" : "FAQs"}
            </a>
          </div>

          <div>
            <Typography
              sx={{ fontSize: "18px", pb: "10px", ...muiStyles.secondaryText2 }}
            >
              {language === "Hindi" ? "संपर्क करें" : "CONTACT US"}
            </Typography>
            <Typography sx={{ fontSize: "18px", ...muiStyles.secondaryText3 }}>
              +91 7483890478
            </Typography>
            <Typography sx={{ fontSize: "18px", ...muiStyles.secondaryText3 }}>
              support@oncophr.com
            </Typography>
          </div>
        </div>
        <hr style={{ color: "#fff" }} />
        <div
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              ...muiStyles.secondaryText3,
              marginLeft: "auto",
            }}
          >
            <Link
              to="/patient/privacy-policy"
              target="_blank"
              style={{ color: "#E6E6E6" }}
            >
              {language === "Hindi" ? "गोपनीयता नीति " : "Privacy Policy "}
            </Link>{" "}
            |{" "}
            <Link
              to="/patient/terms-and-conditions"
              target="_blank"
              style={{ color: "#E6E6E6" }}
            >
              {language === "Hindi"
                ? " नियम और शर्तें"
                : " Terms and Conditions"}
            </Link>
          </Typography>
        </div>
      </div>
      {/* <div className="container--flex container--col" style={{ gap: "0" }}>
        <div className="container--flex2">
          
        </div>
      </div> */}
    </div>
  );
};

export default Footer;
