import { Typography, TextField, Modal, IconButton } from "@mui/material";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import React, { useState } from "react";
import oncoLogo from "../Assets/oncoLogo.png";
import introImage from "../Assets/First.png";
import phoneImage from "../Assets/phone-call.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router";
import { getSessionStorage, loginPassword } from "../Services/patients";
import swal from "sweetalert";
import "../Styles/styles2.css";
import { muiStyles } from "../Styles/muiStyles";
import PrimaryNavbar from "./Elements/PrimaryNavbar";
import SignUpModal from "./Modals/signUpModal";
import AboutUs from "./Elements/AboutUs";
import WhyOncoPHR from "./Elements/WhyOncoPHR";
import GettingStarted from "./Elements/GettingStarted";
import Footer from "./Elements/Footer";
import FAQs from "./Elements/FAQs";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [openSignUpModel, setOpenSignUpModel] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);

  var raw = {
    role: "PATIENT",
    email: "",
    password: "",
  };

  const [values, setValues] = useState(raw);

  const language = getSessionStorage("language");

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : language === "Hindi"
        ? "कृपया एक मान्य ईमेल दर्ज करें"
        : "Please enter a valid email";
    if ("email" in fieldValues)
      temp.email = /[A-Za-z0-9]+/.test(fieldValues.email)
        ? temp.email
        : language === "Hindi"
        ? "ईमेल खाली नहीं हो सकता"
        : "Email cannot be empty";
    if ("password" in fieldValues)
      temp.password =
        fieldValues.password.length > 5
          ? ""
          : language === "Hindi"
          ? "पासवर्ड की लंबाई कम से कम 6 होनी चाहिए"
          : "Password length should be at least 6";

    setErrors({ ...temp });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setValues({
      ...values,
      [name]: value,
    });

    validate({ [name]: value });
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    let temp_val = validate();
    if (!temp_val)
      return swal({
        icon: "error",
        text:
          language === "Hindi"
            ? "गलत फ़ील्ड के लिए जाँच करें"
            : "Check for incorrect fields",
        timer: 2000,
        confirmButtonColor: "#6e326e",
      });
    setLoading(true);
    try {
      const { data } = await loginPassword(values);
      if (data?.error) {
        // alert("error")
        // swal({ text: data.error.message, icon: "error" })
        // setError(data.error);
        throw data;
      } else {
        sessionStorage.setItem(
          "userData",
          JSON.stringify(data?.data?.userDetails)
        );
        navigate("/patient/dashboard/myHealthFiles");
        swal({
          text:
            language === "Hindi"
              ? "सफलतापूर्वक लॉग इन!"
              : "Successfully Logged In!",
          icon: "success",
          timer: 1500,
        });
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      swal({
        text: error?.response?.data?.message,
        icon: "error",
        timer: 2000,
        confirmButtonColor: "#6e326e",
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const handleCloseSignUpModal = () => {
    setOpenSignUpModel(false);
  };

  const handleMessageBox = (prev) => {
    setOpenMessage(!prev);
  };

  // go to top
  // When the user scrolls down 20px from the top of the document, show the button

  window.onscroll = () => detectScroll();

  const detectScroll = () => {
    if (window.location.href.split("/")[4] === "login") {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        document.getElementById("go-top").style.display = "block";
      } else {
        document.getElementById("go-top").style.display = "none";
      }
    }
  };

  // to go to top
  const handleGoToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <div>
      <PrimaryNavbar setOpenSignUpModel={setOpenSignUpModel} />

      {/* Logo and Into section */}
      <div className="login-section-1">
        <div className="intro">
          {/* Logo Image */}
          <img className="logo-image" src={oncoLogo} alt="oncoPHR logo" />
          <Typography
            sx={{
              ...muiStyles.secondaryText,
              fontSize: {
                xs: "18px",
                sm: "22px",
              },
              ml: {
                xs: "40px",
                sm: "170px",
              },
            }}
          >
            {language === "Hindi"
              ? "कैंसर से पीड़ित लोगों के लिए"
              : "For people with cancer"}
          </Typography>
          {/* Intoduction */}
          <Typography
            textAlign="center"
            sx={{
              ...muiStyles.secondaryText,
              fontSize: {
                xs: "22px",
                sm: "26px",
              },
              mt: "40px",
              px: {
                xs: "5px",
                sm: "0",
              },
            }}
          >
            {language === "Hindi"
              ? "अपने सभी स्वास्थ्य रिकॉर्ड सुरक्षित रूप से संग्रहीत करें और उन तक पहुंचें"
              : "Securely store and access all your health records"}
          </Typography>
          <Typography
            textAlign="center"
            sx={{
              ...muiStyles.primaryText,
              fontSize: {
                xs: "24px",
                sm: "2p8x",
              },
              px: {
                xs: "5px",
                sm: "0",
              },
            }}
          >
            {language === "Hindi"
              ? "किसी भी समय, कहीं भी,"
              : "Anytime Anywhere"}
            <span style={{ ...muiStyles.secondaryText }}>
              {language === "Hindi" ? "" : " from "}
            </span>
            {language === "Hindi" ? "किसी भी उपकरण से" : "Any Device"}
          </Typography>
          <img className="logo-image" src={introImage} alt="Intro" />
        </div>

        {/* Login Form */}
        <div className="login-container">
          <Typography
            sx={{
              ...muiStyles.secondaryText,
              fontSize: "24px",
              fontWeight: "600",
            }}
          >
            {language === "Hindi" ? "लॉग इन" : "LOGIN"}
          </Typography>
          <form type="submit">
            <div className="form-container" style={{ marginTop: "60px" }}>
              <div className="input-container">
                <label className="input-label1">
                  {language === "Hindi" ? "ईमेल*" : "Email*"}
                </label>
                <TextField
                  className="input2"
                  variant="standard"
                  type="email"
                  name="email"
                  sx={{
                    ml: {
                      xs: "0",
                      sm: "40px",
                    },
                  }}
                  value={values.email}
                  placeholder={
                    language === "Hindi" ? "ईमेल दर्ज करें" : "Enter Email"
                  }
                  onChange={handleChange}
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  {...(errors.email && {
                    error: true,
                    helperText: errors.email,
                  })}
                />
              </div>
              <div className="input-container">
                <label className="input-label1">
                  {language === "Hindi" ? "पासवर्ड*" : "Password*"}
                </label>
                <TextField
                  className="input2"
                  variant="standard"
                  type="password"
                  name="password"
                  sx={{
                    ml: {
                      xs: "0",
                      sm: "10px",
                    },
                  }}
                  value={values.password}
                  placeholder={
                    language === "Hindi"
                      ? "पासवर्ड दर्ज करें"
                      : "Enter Password"
                  }
                  onChange={handleChange}
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  {...(errors.password && {
                    error: true,
                    helperText: errors.password,
                  })}
                />
              </div>
            </div>
          </form>
          <div style={{ textAlign: "center" }}>
            <button
              className="submit-button secondary-button"
              onClick={(e) => formSubmitHandler(e)}
            >
              <div className="text1">
                {language === "Hindi" ? "सबमिट करें" : "Submit"}
                {loading && (
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: "5px", marginLeft: "10px" }}
                  />
                )}
              </div>
            </button>
          </div>
          <Typography variant="body1" sx={{ textAlign: "center", mt: "10px" }}>
            {language === "Hindi"
              ? "खाता नहीं है? "
              : "Don't have an account? "}

            <span
              style={{ color: "#6E326E", cursor: "pointer" }}
              onClick={() => setOpenSignUpModel(true)}
            >
              {language === "Hindi" ? "साइन अप करें" : "Signup"}
            </span>
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "center", my: "10px" }}>
            {language === "Hindi" ? "पासवर्ड भूल गए? " : " Forgot password? "}
            <span
              style={{ color: "#6E326E", cursor: "pointer" }}
              onClick={() => navigate("/patient/forgot-password")}
            >
              {language === "Hindi" ? "रीसेट करें" : "Reset"}
            </span>
          </Typography>
          <Typography
            textAlign="center"
            sx={{
              ...muiStyles.secondaryText,
              fontSize: "20px",
              mt: "40px",
              px: {
                xs: "5px",
                sm: "150px",
                md: "200px",
              },
            }}
          >
            {language === "Hindi"
              ? "यदि आपको किसी सहायता की आवश्यकता हो, आप हमें यहां कॉल कर सकते हैं +91 7483890478 "
              : "If you need any assistance, you can call us at +91 7483890478 "}
            {language === "Hindi" ? "या हमें ईमेल करें " : "or email us at "}
            <a
              href="mailto:support@oncophr.com"
              style={{ ...muiStyles.secondaryText, fontSize: "20px" }}
            >
              support@oncophr.com
            </a>
          </Typography>
        </div>
      </div>
      <div id="about-us"></div>
      <AboutUs language={language} />
      <GettingStarted language={language} />
      <WhyOncoPHR language={language} />
      {/* <Pricing language={language} setOpenSignUpModel={setOpenSignUpModel} /> */}
      <FAQs language={language} />
      <Footer language={language} />
      <div className="phone-message-container">
        <div className="phone-icon-div">
          <img
            id="phone-icon"
            src={phoneImage}
            alt="phone icon"
            onClick={() => {
              handleMessageBox(openMessage);
            }}
          />
        </div>
        {openMessage && (
          <div className="message-container">
            <div class="arrow">
              <div class="outer"></div>
              <div class="inner"></div>
            </div>
            <div class="message-body">
              <Typography
                variant="body1"
                sx={{ ...muiStyles.primaryText, fontSize: "20px" }}
              >
                {language === "Hindi"
                  ? "यदि आपको किसी सहायता की आवश्यकता हो, आप हमें यहां कॉल कर सकते हैं +91 7483890478 या हमें ईमेल करें support@oncophr.com"
                  : "If you need any assistance, you can call us at +91 7483890478 or email us at support@oncophr.com"}
              </Typography>
            </div>
          </div>
        )}
      </div>
      <Modal open={openSignUpModel} onClose={handleCloseSignUpModal}>
        <SignUpModal setOpenSignUpModel={setOpenSignUpModel} />
      </Modal>
      <div id="go-top">
        <IconButton onClick={handleGoToTop} className="go-top--btn">
          <p className="text-hidden">
            {language === "Hindi" ? "उपर जाये" : "Go to top"}
          </p>
          <KeyboardArrowUpOutlinedIcon
            color="primary"
            sx={{ color: "white", background: "#333" }}
          />
        </IconButton>
      </div>
    </div>
  );
};

export default Login;
